started = () => {
   jQuery.datetimepicker.setLocale('fr');
    $('.target-form input.autocomplete').autocomplete({
        data: {
            "Dakar": null,
            "Thies": null,
            "Ville 3": null,
            "Ville 4": null
        }
    });
  
    $('#datepicker1').datetimepicker({
        timepicker: true,
        datepicker: true,
        format:'d-m-Y H:i',
        onShow: function(ct) {
            this.setOptions({
                maxDate: $('#datepicker2').val() ? $('#datepicker2').val() : false
            })
        }
    });
    
    $('#datepicker2').datetimepicker({
        timepicker: true,
        datepicker: true,
        format:'d-m-Y H:i',
        onShow: function(ct) {
            this.setOptions({
                minDate: $('#datepicker1').val() ? $('#datepicker1').val() : false
            })
        }
    });

    

}

$(document).ready(started());




